import './App.css';
import {
    Box,
    Container,
    FormControlLabel,
    FormLabel,
    IconButton,
    Input,
    Link,
    TextField,
    Typography
} from "@mui/material";
import ListingsList from "./components/ListingsList";
import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import Header from "./components/Header";
import CssBaseline from "@mui/material/CssBaseline";


export default function App() {
    return (
        <Container maxWidth="xl">
            <ToastContainer stacked/>
            <Box>
                <Header/>
                <ListingsList/>
            </Box>
        </Container>
    );
}

