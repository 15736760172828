import React from 'react';
import {
    Box,
    Button,
    Card, CardActions,
    CardContent,
    CardHeader, Checkbox,
    FormControl,
    FormControlLabel, FormLabel, IconButton,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {Refresh, Sync} from "@mui/icons-material";
import {ListProviders} from "../api";
import {pink} from "@mui/material/colors";

export type FilterParams = {
    hidden: boolean | null
    favorited: boolean | null
    providers: string[] | null
}

interface Props {
    onFetchNewListings: (filter: FilterParams) => void,
    onFilterChanged: (filter: FilterParams) => void,
    syncing: boolean
}

interface State {
    hidden: string
    favorited: string
    availableProviders: string[]
    selectedProviders: Map<string, boolean>
}

const hiddenValues: { [key: string]: boolean | null } = {
    "Visible": false,
    "Hidden": true,
    "All": null
}

const favoriteValues: { [key: string]: boolean | null } = {
    "Favorited": true,
    "Not Favorited": false,
    "All": null,
}

export default class ListSidebar extends React.Component<Props, State> {
    state: State = {
        hidden: "Visible",
        favorited: "Not Favorited",
        availableProviders: [],
        selectedProviders: new Map<string, boolean>()
    }

    componentDidMount() {
        ListProviders().then(r => {
            const m = new Map<string, boolean>()
            for (let provider of r.providers) {
                m.set(provider, false)
            }
            this.setState({
                availableProviders: r.providers,
                selectedProviders: m,
            });
        })
    }

    render() {
        return (
            <Box sx={{display: "flex", flexDirection: "column"}}>
                {this.fetchButton()}
                <Card>
                    <CardHeader title="Filters" action={this.RefreshIcon()}/>
                    <CardActions>
                        <Button variant="text"
                                onClick={() => this.setState({
                                    hidden: "All",
                                    favorited: "All",
                                    selectedProviders: new Map<string, boolean>(),
                                }, () => this.props.onFilterChanged(this.getFilter()))}>
                            All
                        </Button>
                        <Button variant="text"
                                onClick={() => this.setState({
                                    hidden: "Visible",
                                    favorited: "Not Favorited",
                                    selectedProviders: new Map<string, boolean>(),
                                }, () => this.props.onFilterChanged(this.getFilter()))}>
                            New
                        </Button>
                        <Button variant="text"
                                onClick={() => this.setState({
                                    hidden: "All",
                                    favorited: "Favorited",
                                    selectedProviders: new Map<string, boolean>(),
                                }, () => this.props.onFilterChanged(this.getFilter()))}>
                            Favs
                        </Button>
                    </CardActions>
                    <CardContent>
                        <FormControl fullWidth>
                            <InputLabel>Visibility</InputLabel>
                            <Select
                                value={this.state.hidden}
                                label={"Visibility"}
                                onChange={(e) => {
                                    this.setState({
                                        hidden: e.target.value,
                                    }, () => this.props.onFilterChanged(this.getFilter()))
                                }}>
                                {Object.entries(hiddenValues).map(([name, val]) => {
                                    return <MenuItem key={name} value={name}>{name} </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <br/>
                        <br/>
                        <FormControl fullWidth>
                            <InputLabel>Favorites</InputLabel>
                            <Select
                                value={this.state.favorited}
                                label={"Favorites"}
                                onChange={(e) => {
                                    this.setState({
                                        favorited: e.target.value,
                                    }, () => this.props.onFilterChanged(this.getFilter()))
                                }}>
                                {Object.entries(favoriteValues).map(([name, val]) => {
                                    return <MenuItem key={name} value={name}>{name} </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <br/>
                        <br/>
                        <FormControl fullWidth>
                            <FormLabel focused={false}>Providers</FormLabel>
                            {this.state.availableProviders.map((p, i) =>
                                <FormControlLabel
                                    key={p}
                                    control={
                                        <Checkbox
                                            checked={this.state.selectedProviders.get(p)}
                                            onChange={(e) =>
                                                this.setState({
                                                    selectedProviders: this.state.selectedProviders.set(p, e.target.checked)
                                                }, () => this.props.onFilterChanged(this.getFilter()))}
                                        />
                                    }
                                    label={p}
                                />
                            )}
                        </FormControl>
                    </CardContent>
                </Card>
            </Box>
        )
    }

    private getFilter(): FilterParams {
        return {
            hidden: hiddenValues[this.state.hidden],
            favorited: favoriteValues[this.state.favorited],
            providers: Array.from(this.state.selectedProviders.entries()).filter(([p, checked]) => checked).map(([p]) => p),
        }
    }

    private fetchButton(): React.JSX.Element {
        return <Button
            startIcon={this.props.syncing ? this.syncIcon() : null}
            disabled={this.props.syncing}
            sx={{marginBottom: 2}}
            variant="outlined"
            onClick={() => {
                this.props.onFetchNewListings(this.getFilter())
            }}>
            Fetch new listings
        </Button>
    }

    private RefreshIcon(): React.JSX.Element {
        return <IconButton
            onClick={() => this.props.onFilterChanged(this.getFilter())}>
            <Refresh/>
        </IconButton>
    }

    private syncIcon(): React.JSX.Element {
        return <Sync sx={{
            animation: "spin 1s linear infinite",
            "@keyframes spin": {
                "0%": {
                    transform: "rotate(360deg)",
                },
                "100%": {
                    transform: "rotate(0deg)",
                },
            },
        }}/>;
    }
}