import React from 'react';
import {FetchListings, ListListings, niceError} from "../api";
import ListingCard from "./ListingCard";
import {Box, Container, Grid, Typography} from "@mui/material";
import {Listing} from "../types";
import {newToast, TOAST_DELAY, TOAST_ERROR, TOAST_INFO, TOAST_SUCCESS} from "../util";
import {PlaceholderListing} from "./PlaceholderListing";
import ListSidebar, {FilterParams} from "./ListSidebar";
import {RestoreFromTrash} from "@mui/icons-material";

interface Props {
}

interface State {
    listings: Map<String, Listing[]>
    syncing: boolean
    loading: boolean
}

export default class ListingsList extends React.Component<Props, State> {
    state: State = {
        listings: new Map<String, Listing[]>(),
        syncing: false,
        loading: true,
    }

    componentDidMount() {
        this.fetch({
            hidden: false,
            favorited: false,
            providers: null,
        })
    }

    onListingUpdate = (listing: Listing, hide: boolean) => {
        const {id, vin} = listing
        const {listings} = this.state
        const prevList = listings.get(vin)!

        if (hide) {
            this.setState({
                listings: new Map(listings.set(vin, prevList.filter((l) => l.id != id)))
            })
        } else {
            if (this.state.listings.get(vin)?.findIndex((l) => l.id == id) == -1) {
                this.setState({
                    listings: new Map(listings.set(vin, [...prevList, listing]))
                })
            } else {
                this.setState({
                    listings: new Map(listings.set(vin, prevList.map((l) => l.id == id ? listing : l)))
                })
            }
        }
    }

    render() {
        const {listings} = this.state
        let right: any =
            Array.from({length: 6}, (_, i) =>
                <Grid item key={i} xs={12} sm={6} md={4}>
                    <PlaceholderListing/>
                </Grid>
            )

        if (!this.state.loading) {
            const entries = Array.from(listings.entries())
            const cards = entries.filter(([_, ls]) => ls.length > 0).map(([vin, ls]) => {
                return (
                    <Grid item key={vin.toString()} xs={12} sm={6} md={4}>
                        <ListingCard listings={ls} onListingUpdate={this.onListingUpdate}/>
                    </Grid>
                );
            })

            right = cards
            if (cards.length == 0) {
                right = <Grid item xs={12} marginTop={{sm: 20}} display={"flex"}
                              justifyContent={"center"}>
                    <Typography variant="h4" color="text.disabled">
                        👻 No listings found
                    </Typography>
                </Grid>
            }
        }

        return (
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4} md={2}>
                    <ListSidebar
                        onFetchNewListings={this.fetchNewListings}
                        onFilterChanged={this.onFilterChanged}
                        syncing={this.state.syncing}
                    />
                </Grid>
                {<Grid item xs={12} sm={8} md={10}>
                    <Grid container spacing={4}>
                        {right}
                    </Grid>
                </Grid>}
            </Grid>
        )
    }

    groupListingsByVIN(listingsList: Listing[]): Map<String, Listing[]> {
        let listings = new Map<String, Listing[]>();

        listingsList?.forEach(function (listing: Listing) {
            if (!listings.has(listing.vin)) {
                listings.set(listing.vin, []);
            }

            listings.get(listing.vin)?.push(listing)
        })

        return listings;
    }

    private fetch = (filter: FilterParams) => {
        this.setState({
            syncing: false,
            loading: true,
        })

        ListListings(filter).then(listings => {
            this.setState({
                listings: this.groupListingsByVIN(listings),
            })
        }).finally(() => this.setState({
            syncing: false,
            loading: false,
        }))
    }

    fetchNewListings = (filter: FilterParams) => {
        const closer = newToast("Fetching new listings...", TOAST_INFO, false);
        this.setState({
            syncing: true,
            loading: true,
        })

        FetchListings(filter).then(r => {
            closer(<Container sx={{display: 'flex', gap: 1, flexDirection: "column"}}>
                    < Typography variant={"h4"} py={2} textAlign={"center"}>
                        🏎 Fetch finished 🏁
                    </Typography>
                    <Typography variant={"body1"}>
                        🎣 Fetched <b>{r.fetched}</b> listings(s)
                    </Typography>
                    <Typography variant={"body1"}>
                        💾 Saved <b>{r.new_listings}</b> new listings(s)
                    </Typography>
                    <Typography variant={"body1"}>
                        👀 Found <b>{r.new_vins}</b> new VIN(s)
                    </Typography>
                    <Typography variant={"body1"}>
                        💥 Total errors: <b>{r.errors.length}</b>
                    </Typography>
                    {r.errors.map((err, i) =>
                        <Typography key={i} variant={"caption"}>
                            💥 {err}
                        </Typography>
                    )}
                </Container>,
                TOAST_SUCCESS,
                {style: {width: 500, marginLeft: (-500 / 2.7)}, autoClose: TOAST_DELAY*4}
            )
            this.setState({
                listings: this.groupListingsByVIN(r.listings),
            })
        }).catch(err => {
            closer(`Failed to fetch listings: ${niceError(err)}`, TOAST_ERROR);
        }).finally(() => this.setState({
            syncing: false,
            loading: false
        }))
    }
    onFilterChanged = (filter: FilterParams) => {
        this.fetch(filter)
    }
}


