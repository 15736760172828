import axios, {Axios, AxiosError} from 'axios';
import {newToast, TOAST_ERROR} from "./util";
import {FilterParams} from "./components/ListSidebar";
import {FetchListingResponse, Listing, ListProvidersResponse} from "./types";

const API = axios.create({baseURL: baseUrl()})
API.interceptors.request.use((config) => {
    config.auth = {
        username: "",
        password: localStorage.getItem("password") || ""
    }
    return config
})
API.interceptors.response.use(
    (response) => response,
    (err) => {
        newToast(`${niceError(err)}`, TOAST_ERROR)
        return Promise.reject(err)
    }
)
export default API;

function baseUrl() {
    if (process.env.NODE_ENV === "production") {
        return `https://car-search-toggbfn3dq-ue.a.run.app`
    } else {
        return `http://localhost:8080`
    }
}

export async function ListListings(filter: FilterParams): Promise<Listing[]> {
    return API.get(`/listings`, {
        params: filter,
    }).then(res => {
        return res.data.listings;
    })
}

export async function FavoriteListing(id: string, favorite: boolean): Promise<Listing> {
    if (favorite) {
        return API.put(`/listings/${id}/favorite`).then(res => {
            return res.data.listing;
        })
    } else {
        return API.delete(`/listings/${id}/favorite`).then(res => {
            return res.data.listing;
        })
    }
}

export async function MarkAsSeen(id: string): Promise<Listing> {
    return API.put(`/listings/${id}/seen`).then(res => {
        return res.data.listing;
    })
}

export async function HideListing(id: string, hide: boolean): Promise<Listing> {
    if (hide) {
        return API.put(`/listings/${id}/hide`).then(res => {
            return res.data.listing;
        })
    } else {
        return API.delete(`/listings/${id}/hide`).then(res => {
            return res.data.listing;
        })
    }
}

export async function FetchListings(filter: FilterParams): Promise<FetchListingResponse> {
    return API.post(`/listings/fetch`, null, {
        params: filter
    }).then(res => {
        return res.data;
    })
}

export async function ListProviders(): Promise<ListProvidersResponse> {
    return API.get(`/providers`).then(res => {
        return res.data;
    })
}

export function niceError(err: AxiosError | any): string {
    if (err instanceof AxiosError && err.response?.data["error"] != null) {
        return `${friendlyHttpStatus[err.response.status]}: ${err.response?.data["error"]}`
    }

    return err.toString()
}

export function handleError(msg: string): (err: any) => void {
    return (err) => {
        newToast(`${msg}: ${niceError(err)}`, TOAST_ERROR)
    }
}

const friendlyHttpStatus: { [key: number]: string } = {
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-Authoritative Information',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    306: 'Unused',
    307: 'Temporary Redirect',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Required',
    413: 'Request Entry Too Large',
    414: 'Request-URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Requested Range Not Satisfiable',
    417: 'Expectation Failed',
    418: `I'm a teapot`,
    429: 'Too Many Requests',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
};