import React from "react";
import {Box, TextField, Typography} from "@mui/material";

interface Props {
}

interface State {
}

export default class Header extends React.Component<Props, State> {
    state: State = {
        listings: [],
        syncing: false,
        loading: true,
    }

    render() {
        return (
            <Box display="flex" justifyContent="space-between" alignItems={"center"} sx={{paddingBottom: 2}}>
                <Typography variant="h4">
                    Car Search
                </Typography>
                <TextField
                    variant={"outlined"}
                    label="Password"
                    margin="normal"
                    id="password"
                    type="password"
                    defaultValue={localStorage.getItem("password")}
                    onChange={(e) => {
                        localStorage.setItem("password", e.target.value)
                    }}/>
            </Box>
        )
    }
}
