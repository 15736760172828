import React from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Skeleton, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, Tabs
} from "@mui/material";

export function PlaceholderListing(): React.JSX.Element {
    const randomSize = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const randomRow = (idx: number) => {
        return <TableRow key={idx}>
            <TableCell><Skeleton animation={"pulse"} variant="text" width={randomSize(25, 40)} height={24}/></TableCell>
            <TableCell><Skeleton animation={"pulse"} variant="text" width={randomSize(25, 40)} height={24}/></TableCell>
            <TableCell><Skeleton animation={"pulse"} variant="text" width={randomSize(25, 40)} height={24}/></TableCell>
            <TableCell><Skeleton animation={"pulse"} variant="text" width={randomSize(25, 40)} height={24}/></TableCell>
        </TableRow>
    }

    return (
        <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <Skeleton variant="rectangular" height={300} width={"100%"} animation={"wave"}/>
            <CardActions>
                {Array.from({length: randomSize(2, 5)}).map((_, i) => <Skeleton key={i} variant="rounded" width={24}
                                                                                height={24}/>)}
            </CardActions>
            <CardContent sx={{flexGrow: 1}}>
                <Box gap={4} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="text" width={randomSize(100, 300)} height={36}/>
                        <Skeleton variant="text" width={randomSize(50, 150)} height={36}/>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
                        <Skeleton variant="text" width={50} height={36}/>
                        <Skeleton variant="text" width={randomSize(50, 100)} height={36}/>
                        <Skeleton variant="text" width={randomSize(50, 100)} height={36}/>
                    </Box>
                </Box>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {Array.from({length: 3}).map((_, i) => randomRow(i))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}