import {toast, TypeOptions} from "react-toastify";
import {ToastContent, ToastOptions, UpdateOptions} from "react-toastify/dist/types";

export const TOAST_DELAY = 2500;
export const TOAST_DEFAULT = 'default';
export const TOAST_INFO = 'info';
export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';

export function isEmpty(val: string): boolean {
    return val == null || val === ""
}


export function newToast(msg: ToastContent, type: TypeOptions = TOAST_INFO, autoClose: number | false = TOAST_DELAY, opts?: ToastOptions): (msg: ToastContent, type?: TypeOptions, opts?: UpdateOptions) => void {
    const toastId = toast(msg, {
        ...opts,
        autoClose,
        type,
        isLoading: autoClose == false,
        closeOnClick: autoClose != false
    });
    return (msg: ToastContent, type: TypeOptions = TOAST_INFO, opts?: UpdateOptions) => {
        toast.update(toastId, {
            ...opts,
            render: msg,
            type: type,
            autoClose: opts?.autoClose ?? TOAST_DELAY,
            isLoading: false,
            closeOnClick: true
        });
    }
}


const moneyFormatter = Intl.NumberFormat('en', {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
});

export function formatNumber(n: number, options?: { money: boolean }): string {
    if (options?.money) {
        return moneyFormatter.format(n)
    }

    if (n < 1e3) return n.toString();
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "k";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "m";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "b";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "t";

    return n.toString();
}