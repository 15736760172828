import {red} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';

// A custom theme for this app
export const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#0194e4',
        },
        secondary: {
            main: '#0194e4',
        },
        error: {
            main: red.A400,
        },
    },
    components: {
        MuiTableCell: {
            defaultProps: {
                size: "small",
            },
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    // padding: 8,
                    padding: 6,
                },
            },
        },
    },
});
