import React, {SyntheticEvent} from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    IconButton,
    Link, Skeleton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    Typography
} from "@mui/material";
import {
    Delete,
    FactCheck,
    Favorite,
    FavoriteBorderOutlined,
    NewReleases,
    RestoreFromTrash,
    Undo,
    Visibility
} from "@mui/icons-material";
import {pink} from '@mui/material/colors';
import {FavoriteListing, HideListing, MarkAsSeen} from "../api";
import {Listing} from "../types";
import {formatNumber, newToast, TOAST_DELAY, TOAST_SUCCESS} from "../util";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

interface Props {
    listings: Listing[]
    onListingUpdate: (listing: Listing, hide: boolean) => void
}

interface State {
    selectedProvider: number
}


export default class ListingCard extends React.Component<Props, State> {
    state = {
        selectedProvider: 0,
    }

    markAsSeen = (listing: Listing) => {
        if (listing.seen != null) {
            return
        }

        MarkAsSeen(listing.id).then(r => {
            this.props.onListingUpdate(r, false)
        })
    }

    toggleFavorite = (listing: Listing) => {
        FavoriteListing(listing.id, listing.favorited == null).then(r => {
            this.props.onListingUpdate(r, false)
        })
    }

    toggleHidden = (listing: Listing) => {
        const hide = listing.hidden == null
        HideListing(listing.id, hide).then(r => {
            this.props.onListingUpdate(r, hide)
            newToast(`Listing ${hide ? "hidden" : "restored"}`, TOAST_SUCCESS, TOAST_DELAY, {
                closeButton: (_) =>
                    <Chip color={"default"} icon={<Undo/>} label={"Undo"} size="medium"
                          onClick={() => this.toggleHidden(r)}/>,
            })
            this.setState({
                selectedProvider: 0,
            })
        })
    }

    handleChange = (event: SyntheticEvent, newValue: number) => {
        this.setState({
            selectedProvider: newValue,
        })
    }

    render() {
        const {listings} = this.props
        const {selectedProvider} = this.state

        return (
            <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Tabs
                    value={selectedProvider}
                    onChange={this.handleChange}
                    TabIndicatorProps={{sx: {display: 'none'}}}
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                    }}>
                    {
                        listings.map((listing, idx) => (
                            <Tab key={idx} label={listing.provider}/>
                        ))
                    }
                </Tabs>
                {
                    listings.map((listing, idx) => {
                        return (
                            <div key={listing.id} hidden={idx != selectedProvider}>
                                <Carousel
                                    canAutoPlay={false}
                                    images={listing.photo_urls.map((url, idx) => ({
                                        src: url,
                                        thumbnail: listing.thumbnail_urls[idx]
                                    }))}
                                    style={{height: 300}}
                                    shouldMaximizeOnClick={true}
                                    shouldMinimizeOnClick={true}
                                    shouldMinimizeOnSwipeDown={true}
                                    transitionDurationMin={0}
                                    transitionDurationMax={1}
                                    hasSizeButton={false}
                                    hasSizeButtonAtMax={false}
                                    onTap={() => {
                                        this.markAsSeen(listing);
                                    }}
                                />
                                <CardActions>
                                    {this.FavIcon(listing)}
                                    {this.HideIcon(listing)}
                                    {this.ReportIcon(listing)}
                                    {this.SeenChip(listing)}
                                </CardActions>
                                <CardContent>
                                    <Box gap={4} display={"flex"} alignItems={"center"}
                                         justifyContent={"space-between"}>
                                        <Typography sx={{fontWeight: 700}} flex={50} gutterBottom
                                                    variant="subtitle1">{listing.title}</Typography>
                                        <Box display={"flex"} flexDirection={"column"} alignItems={"end"}>
                                            <Typography
                                                variant="subtitle2">{formatNumber(listing.price, {money: true})}</Typography>
                                            <Typography variant="body2">{formatNumber(listing.mileage)} mi.</Typography>
                                            <Typography variant="body2">Unknown location</Typography>
                                        </Box>
                                    </Box>
                                    {this.Stats(listing)}
                                </CardContent>
                            </div>
                        );
                    })
                }
            </Card>
        )
    }

    SeenChip(listing: Listing) {
        const seen = listing.seen != null;
        return <Chip color={seen ? "default" : "primary"} icon={seen ? <Visibility/> : <NewReleases/>}
                     label={`${seen ? "Viewed" : "New"}`} size="small"/>
    }

    FavIcon(listing: Listing) {
        const FIcon = listing.favorited == null ? FavoriteBorderOutlined : Favorite
        return <IconButton
            sx={{color: pink[500]}}
            onClick={() => this.toggleFavorite(listing)}>
            <FIcon/>
        </IconButton>
    }

    HideIcon(listing: Listing) {
        const FIcon = listing.hidden == null ? Delete : RestoreFromTrash
        return <IconButton
            onClick={() => this.toggleHidden(listing)}>
            <FIcon/>
        </IconButton>
    }

    ReportIcon(listing: Listing) {
        if (listing.car_history_url == null) {
            return null;
        }

        return (
            <Link href={listing.car_history_url} target={"_blank"}>
                <IconButton>
                    <FactCheck/>
                </IconButton>
            </Link>
        )
    }

    Stats(listing: Listing) {
        return <TableContainer>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Ext. Color</TableCell>
                        <TableCell>{listing.exterior_color}</TableCell>
                        <TableCell>Int. Color</TableCell>
                        <TableCell>{listing.interior_color}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Trans</TableCell>
                        <TableCell>{listing.transmission}</TableCell>
                        <TableCell>VIN</TableCell>
                        <TableCell>{listing.vin}</TableCell>
                    </TableRow>
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell>Dealer</TableCell>
                        <TableCell sx={{maxWidth: '5rem'}}>
                            <Link target={"_blank"} href={listing.dealer_url}>
                                <Typography
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                    }}>
                                    {listing.dealer}
                                </Typography>
                            </Link>
                        </TableCell>
                        <TableCell><Link target={"_blank"}
                                         href={listing.detail_url}
                                         onClick={() => this.markAsSeen(listing)}
                        >{listing.provider}</Link></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    }


}
