import React from 'react';
import './index.css';
import App from './App';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from "./theme";
import {createRoot} from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <App/>
        </ThemeProvider>
    </React.StrictMode>
);
